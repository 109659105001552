import { FontIcon } from "@fluentui/react";
import styled from "styled-components";

export const panelStyles = { root: { padding: "400" } };

export const Container = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ImageUploadContainer = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const UploaderContainer = styled.div`
  margin-top: -6px;
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  label {
    background-color: #fff;
    border: solid 1px #4b4949;
    padding: 8px;
    width: 100%;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: 0.2s ease;

    &:hover {
      background-color: #faf5f5;
    }
  }

  span {
    text-align: center;
    font-weight: 500;
    margin-top: 8px;
  }
`;

export const FileUploader = styled.input`
  display: none;
`;

export const DeleteIcon = styled.div`
  position: absolute;
  bottom: 12px;
  right: 0;
  z-index: 2;
  background: #f1eeee;

  i {
    font-size: 12px;
  }
`;

export const PreviewIcon = styled(FontIcon)``;

export const PreviewContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 12px;
  padding: 14px 8px;
  border-radius: 2px;
  align-items: center;
  box-shadow: -2px 2px 10px 4px rgba(235, 228, 228, 0.2);
  background: #f1eeee;
  position: relative;

  div {
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
    }
  }
`;

export const TextContainer = styled.div`
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: red;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;
`;