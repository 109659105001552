import React from "react";
import { Nav } from "@fluentui/react/lib/Nav";
import { Stack } from "@fluentui/react";
import { horizontalGapStackTokens } from "./style";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function SideBar() {
  const pathName = window?.location?.pathname;
  let jobTitle = sessionStorage.getItem("jobTitle");
  const { adInfo } = useSelector((store) => store.auth);
  const tec = pathName === "/" || pathName === "/portfolio" || pathName === "/comunidade";
  const adm = pathName === "/" || pathName === "/extratos" || pathName === "/cupons";
  const navigate = useNavigate();

  const masterLinks = [
    {
      links: [
        {
          name: "Sinais",
          url: "/",
          key: "key1",
        },
        {
          name: "Clientes",
          url: "/clientes",
          key: "key2",
        },
        {
          name: "Portifólios",
          url: "/portfolio",
          key: "key3",
        },
        // {
        //   name: 'Extrato de pagamentos',
        //   url: '/extratos',
        //   key: 'key4'
        // },
        // {
        //   name: 'Administradores',
        //   url: '/administradores',
        //   key: 'key5'
        // },
        {
          name: "Cupons",
          url: "/cupons",
          key: "key4",
        },
        {
          name: "Comunidade",
          url: "/comunidade",
          key: "key5",
        },
        {
          name: "Pop-up",
          url: "/popup",
          key: "key6",
        },
      ],
    },
  ];

  const tecLinks = [
    {
      links: [
        {
          name: "Sinais",
          url: "/",
          key: "key1",
        },
        {
          name: "Portifólios",
          url: "/portfolio",
          key: "key2",
        },
        {
          name: "Comunidade",
          url: "/comunidade",
          key: "key3",
        },
      ],
    },
  ];

  const admLinks = [
    {
      links: [
        {
          name: "Clientes",
          url: "/",
          key: "key1",
        },
        {
          name: "Cupons",
          url: "/cupons",
          key: "key2",
        },
      ],
    },
  ];

  // Control links
  const renderLinks = () => {
    switch (jobTitle || adInfo?.jobTitle) {
      case "Técnico":
        return tecLinks;
      case "Administrativo":
        return admLinks;
      case "Master":
        return masterLinks;
      default:
        return null;
    }
  };

  const navStyles = {
    root: {
      minWidth: 220,
    },
  };

  useEffect(() => {
    if (jobTitle === 'Técnico' && !tec) {
      navigate('/')
    }

    if (jobTitle === 'Administrativo' && !adm) {
      navigate('/')
    }
  }, [jobTitle, adInfo?.jobTitle]);

  return (
    <Stack horizontal tokens={horizontalGapStackTokens}>
      <Nav styles={navStyles} groups={renderLinks()} />
    </Stack>
  );
}

export default SideBar;
