import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MessageBar, MessageBarType } from "@fluentui/react";
import HeaderFilterList from "./components/headerFilterList";
import PopUpPanel from "./components/popupPanel";
import listColumns from "./listColumns";
import { Container } from "./style";
import CommonScrollList from "../../core/container/commomScrollList";
import {
  getPopupById,
  getPopupsList,
  setPainelIsOpen,
} from "../../core/redux/actions/popupAction";

const PopupView = () => {
  const dispatch = useDispatch();
  const {
    list,
    listLoading,
    listError,
    openDetailPanel,
  } = useSelector((store) => store.popup);

  useEffect(() => {
    if (!openDetailPanel) {
      console.log(openDetailPanel)
      dispatch(getPopupsList());
    }
  }, [openDetailPanel]);

  const handleOpenEditPanel = (id) => {
    dispatch(setPainelIsOpen(true));
    if (id) dispatch(getPopupById(id));
  };

  return (
    <Container>
      {listError && (
        <MessageBar messageBarType={MessageBarType.error} onDismiss={() => null}>
          OCORREU UM ERRO AO CHAMAR A LISTA DE POPUPS
        </MessageBar>
      )}
      <HeaderFilterList />
      <CommonScrollList
        idList="popupsList"
        loading={listLoading}
        enableShimmer={true}
        columns={listColumns(handleOpenEditPanel)}
        list={list}
        getMore={() => null}
      />
      <PopUpPanel />
    </Container>
  );
};

export default PopupView;
