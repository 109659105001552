import React from "react";
import {
  DatePickerInput,
  Container,
  InitialFilters,
  CreateSignalButton,
  ButtonsAlign,
  TextInput,
  ComboInput,
} from "./style";
import { DefaultButton } from "@fluentui/react/lib/Button";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  getPopupById,
  getPopupsList,
  setPainelIsOpen,
  updateFilter,
} from "../../../../core/redux/actions/popupAction";

const popupStatusEnum = [
  { key: null, text: "Todos" },
  { key: true, text: "Ativos" },
  { key: false, text: "Não ativos" },
];

const HeaderFilterList = () => {
  const dispatch = useDispatch();
  const { filters } = useSelector((store) => store.cupom);

  return (
    <Container>
      <InitialFilters>
        <TextInput
          label="Nome"
          placeholder="Digite o nome..."
          value={filters.name}
          onChange={(e, val) => dispatch(updateFilter("title", val))}
        />
        <ComboInput
          placeholder="Selecione uma opção..."
          label="Status"
          options={popupStatusEnum}
          value={filters.active}
          onChange={(ev, val) => dispatch(updateFilter("isActive", val.key))}
        />
        <DatePickerInput
          label="Valido até"
          placeholder="Selecione uma data..."
          value={filters.startDate}
          showMonthPickerAsOverlay={true}
          onSelectDate={(val) => dispatch(updateFilter("expiresAt", val))}
          formatDate={(date) => moment(date).format("DD/MM/YYYY")}
        />
        <ButtonsAlign>
          <DefaultButton
            onClick={() => dispatch(getPopupsList())}
            iconProps={{ iconName: "Search" }}
          >
            Buscar
          </DefaultButton>
        </ButtonsAlign>

        <CreateSignalButton>
          <ButtonsAlign>
            <DefaultButton
              text="Criar Pop-up"
              onClick={() => {
                dispatch(setPainelIsOpen(true));
                dispatch(getPopupById(null));
              }}
              iconProps={{ iconName: "Add" }}
            />
          </ButtonsAlign>
        </CreateSignalButton>
      </InitialFilters>
    </Container>
  );
};

export default HeaderFilterList;
