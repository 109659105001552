import moment from "moment";
import { popupApi } from "../constants/api";
import axiosPrivate from "../utils/api/privateApi";

export const requestPopupList = (filter) => {
  let query = '';

  if (filter.title) query += `&title=${filter.title}`;
  if (filter.expiresAt) query += `&expiresAt=${moment(filter.expiresAt).format('YYYY-MM-DDThh:mm:ssZ')}`;
  if (filter.isActive !== null && filter.isActive !== undefined) query += `&isActive=${filter.isActive}`;

  return new Promise((resolve, reject) => {
    axiosPrivate
      .get(`${popupApi}${query?.replace('&', '?')}`)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
}

export const requestUpdatePopup = (popup) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .put(`${popupApi}/${popup.id}`, popup)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
}

export const requestCreatePopup = (cupom) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .post(`${popupApi}`, cupom)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
}

export const requestDeletePopup = (id) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .delete(`${popupApi}/${id}`)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
}

export const requestSetPopupActive = (id) => {
  return new Promise((resolve, reject) => {
    axiosPrivate
      .post(`${popupApi}/set-active?id=${id}`)
      .then((r) => resolve(r.data))
      .catch((e) => reject(e));
  });
}