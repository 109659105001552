const enviroment = process.env.REACT_APP_CRYPTO_ENVIROMENT;

export const signalApi = "/admin/Signals";
export const portfolioApi = "/admin/Wallet";
export const portfolioApiTransaction = "/admin/Wallet/add-transaction";
export const tradeTypePercentileApi = "/admin/Signals/trade-type-percentile";
export const coinApi = "/Coin";
export const coinApiWallet = "/admin/Wallet/coin-options";
export const instructionApi = "/admin/Signals/add-instruction";
export const signalApiId = "/admin/Signals";
export const signalApiIdClose = "admin/Signals/close";
export const walletApi = "/admin/Wallet";
export const walletUpdateApi = "/admin/Wallet/add-transaction";
export const walletCloseApi = "/admin/Wallet/close-wallet";
export const walletProfitApi = "/admin/Wallet/profit";
export const cupomApi = "/admin/coupons";
export const popupApi = "/admin/in_app_marketing";

export const getApiUrl = () => {
  if (enviroment === "development") {
    return "https://crypto-api.bluepond-d7bd3617.brazilsouth.azurecontainerapps.io/api";
  } else {
    return "https://crypto-api-prod.bluepond-d7bd3617.brazilsouth.azurecontainerapps.io/api";
  }
};