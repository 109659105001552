const initialState = {
  list: [],
  listLoading: false,
  listError: null,
  openDetailPanel: false,
  successMessage: "",
  popup: {
    isActive: false,
    title: "",
    expiresAt: "",
    availableTo: 0,
    imageUrl: "",
    imageUrlMobile: ""
  },
  popupLoading: false,
  popupError: null,
  filters: {
    name: "",
    startDate: "",
    active: null,
  },
};

const popupReducer = (state = initialState, action) => {
  switch (action.type) {
    case "POPUP_LIST_LOADING":
      return {
        ...state,
        listLoading: action.payload,
        listError: null,
      };
    case "POPUP_LIST_ERROR":
      return {
        ...state,
        listLoading: false,
        list: [],
        listError: action.payload,
      };
    case "POPUP_LIST_SUCCESS":
      return {
        ...state,
        listLoading: false,
        list: action.payload,
      };

    case "GET_POPUP_BY_ID_SUCCESS":
      return {
        ...state,
        popup: action.payload,
        popupError: null,
      };
    case "GET_POPUP_BY_ID_FAIL":
      return {
        ...state,
        popup: initialState.popup,
        popupError: action.payload,
      };

    case "POPUP_UPDATE_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };

    case "POPUP_PAINEL_IS_OPEN":
      return {
        ...state,
        openPanel: action.payload,
        popup: !action.payload ? initialState.popup : state.popup,
      };

    case "UPDATE_POPUP_VALUE":
      return {
        ...state,
        popup: {
          ...state.popup,
          [action.payload.key]: action.payload.value,
        },
      };

    case "POPUP_UPDATE_LOADING":
      return {
        ...state,
        popupLoading: true,
        popupError: null,
      };
    case "POPUP_UPDATE_SUCCESS":
      return {
        ...state,
        popupLoading: false,
        successMessage: "Popup sincronizado com sucesso.",
      };
    case "POPUP_UPDATE_MESSAGE":
      return {
        ...state,
        successMessage: action.payload,
      };
    case "POPUP_UPDATE_ERROR":
      return {
        ...state,
        popupLoading: false,
        popupError: action.payload,
      };
    case "POPUP_DELETE_START":
      return {
        ...state,
        popupLoading: false,
        popupError: action.payload,
      };
    case "POPUP_SUCCESS_START":
      return {
        ...state,
        popupLoading: false,
        popupError: action.payload,
      };
    case "POPUP_DELETE_ERROR":
      return {
        ...state,
        popupLoading: false,
        popupError: action.payload,
      };

    default:
      return state;
  }
};

export default popupReducer;
