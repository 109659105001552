import { legacy_createStore as createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import auth from './reducers/authReducer';
import user from './reducers/userReducer';
import signal from './reducers/signalsReducer';
import coins from './reducers/coinReducer';
import instruction from './reducers/instructionReducer';
import wallet from './reducers/walletReducer';
import clients from './reducers/clientsReducer';
import cupom from './reducers/cupomsReducer';
import community from './reducers/communityReducer';
import popup from './reducers/popupReducer';

const logger = createLogger();

let middleware = [];

if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, thunk, logger];
} else {
  middleware = [...middleware, thunk];
}

const rootReducer = combineReducers({
  auth,
  user,
  signal,
  coins,
  instruction,
  wallet,
  clients,
  cupom,
  community,
  popup
});

export default createStore( rootReducer, applyMiddleware(...middleware));
