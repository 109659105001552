import {
  requestPopupList,
  requestUpdatePopup,
  requestCreatePopup,
  requestDeletePopup,
  requestSetPopupActive,
} from "../../services/popup";

/**
 * @description Search POPUP by id in the list
 * @param {string} id
 */
export const getPopupById = (id) => async (dispatch, store) => {
  if (!id) return;

  try {
    if (!store().popup.list) throw new Error("List de popups não encontrada");
    const data = store().popup.list.find((item) => item.id === id);
    if (!data) throw new Error("Popup não encontrado");

    dispatch({ type: "GET_POPUP_BY_ID_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_POPUP_BY_ID_FAIL",
      payload: error,
    });
  }
};

/**
 * @description Search POPUP by id in the list
 */
export const getPopupsList = () => async (dispatch, store) => {
  if (store().popup.listLoading) return;

  dispatch({ type: "POPUP_LIST_LOADING" });

  try {
    const response = await requestPopupList(store().popup.filters);
    dispatch({
      type: "POPUP_LIST_SUCCESS",
      payload: response || [],
    });
  } catch (e) {
    console.error("Ocorreu um erro ao carregar a lista de popups", e);
    dispatch({ type: "POPUP_LIST_ERROR", payload: false });
  }
};

/**
 * @description set POPUP painel is open
 * @param {boolean} isOpen
 */
export const setPainelIsOpen = (isOpen) => ({
  type: "POPUP_PAINEL_IS_OPEN",
  payload: isOpen,
});

/**
 * @description update POPUP filters values
 * @param {string} filterName
 */
export const updateFilter = (filterName, value) => {
  const newValue = {};
  newValue[filterName] = value;

  return {
    type: "POPUP_UPDATE_FILTER",
    payload: newValue,
  };
};

export const updatePopupValue = (key, value) => ({
  type: "UPDATE_POPUP_VALUE",
  payload: { key, value },
});

export const resetError = () => ({
  type: "POPUP_UPDATE_ERROR",
  payload: "",
});

export const resetSuccess = () => ({
  type: "POPUP_UPDATE_MESSAGE",
  payload: "",
});

export const syncPopupValues = () => async (dispatch, store) => {
  const { popup } = store().popup;

  dispatch({ type: "POPUP_UPDATE_LOADING" });
  const clientTypes = popup.availableTo.map((i) => parseInt(i));

  const body = {
    ...popup,
    availableTo: clientTypes,
  }

  try {
    if (popup.id) {
      if (popup.isActive) {
        await requestSetPopupActive(popup.id);
      }
      await requestUpdatePopup(body);
      dispatch(getPopupsList(false));
    } else {
      await requestCreatePopup(body);
      dispatch(getPopupsList(false));
      dispatch(setPainelIsOpen(false));
    }

    dispatch({ type: "POPUP_UPDATE_SUCCESS" });
  } catch (error) {
    const message = error?.response?.data?.errors?.Messages?.[0];
    dispatch({
      type: "POPUP_UPDATE_ERROR",
      payload: message || "Error ao sincronizar o popup",
    });
  }
};

export const deletePopup = (id) => async (dispatch, store) => {
  dispatch({ type: "POPUP_DELETE_START" });

  try {
    await requestDeletePopup(id);
    dispatch(getPopupsList(false));
    dispatch(setPainelIsOpen(false));
    dispatch({ type: "POPUP_DELETE_SUCCESS" });
  } catch (error) {
    const message = error?.response?.data?.errors?.Messages?.[0];
    dispatch({
      type: "POPUP_DELETE_ERROR",
      payload: message || "Error ao deletar o popup",
    });
  }
};
