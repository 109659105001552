import moment from "moment";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  panelStyles,
  Container,
  ImageUploadContainer,
  UploaderContainer,
  FileUploader,
  PreviewContainer,
  PreviewIcon,
  TextContainer,
  DeleteIcon,
  ActionsContainer,
} from "./style";
import {
  ComboBox,
  DatePicker,
  Dropdown,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
  Toggle,
  IconButton,
  DefaultButton,
  TeachingBubble,
} from "@fluentui/react";
import { storage } from "../../../../core/constants/firebase";
import {
  updatePopupValue,
  setPainelIsOpen,
  resetError,
  resetSuccess,
  syncPopupValues,
  deletePopup,
} from "../../../../core/redux/actions/popupAction";
import { useBoolean, useId } from "@fluentui/react-hooks";
import { clientPlanStatus } from "../../listColumns";
const closeIcon = { iconName: "ChromeClose" };

const userType = [
  {
    key: "0",
    text: "Ativo",
  },
  {
    key: "2",
    text: "Cancelado",
  },
  {
    key: "3",
    text: "Expirado",
  },
  {
    key: "4",
    text: "Free",
  },
  {
    key: "5",
    text: "Todos",
  },
];

const PopupPanel = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [fileMobile, setFileMobile] = useState(null);
  const [planIds, setPlanIds] = useState([]);
  const [loadingFirebase, setLoadingFirebase] = useState(false);
  const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] =
    useBoolean(false);
  const { openPanel, popup, popupLoading, popupError, successMessage } =
    useSelector((state) => state.popup);
  const buttonId = useId("targetButton");
  const [fieldError, setFieldError] = useState({});

  const validateFields = (field, value) => {
    switch (field) {
      case "title":
        if (value.length < 3) {
          setFieldError({
            ...fieldError,
            title: "O nome do popup deve ter no mínimo 3 caracteres",
          });
        } else {
          setFieldError({ ...fieldError, title: "" });
        }
        dispatch(updatePopupValue(field, value));
        break;
      case "availableTo":
        if (value.length < 1) {
          setFieldError({
            ...fieldError,
            availableTo: "Você deve selecionar o tipo de cliente",
          });
        } else {
          setFieldError({ ...fieldError, title: "" });
        }
        dispatch(updatePopupValue(field, value));
        break;
      case "imageUrl":
        if (value === "") {
          setFieldError({
            ...fieldError,
            imageUrl: "Você deve utilizar uma imagem",
          });
        } else {
          setFieldError({ ...fieldError, title: "" });
        }
        dispatch(updatePopupValue(field, value));
        break;
      default:
        dispatch(updatePopupValue(field, value));
        break;
    }
  };

  const uploadImageFirebase = async (file) => {
    const storageRef = ref(storage, `popup/images/${file.name}`);

    return await uploadBytesResumable(storageRef, file).then((uploadResult) => {
      return getDownloadURL(uploadResult.ref);
    });
  };

  const dispatchForm = async () => {
    try {
      if (fieldError.title || fieldError.expiresAt || fieldError.imageUrl) {
        return;
      }

      if (!popup?.id) {
        dispatch(updatePopupValue("availableTo", planIds));
      }

      if (file) {
        setLoadingFirebase(true);
        const uploadedImage = await uploadImageFirebase(file);
        const uploadedImageMobile = await uploadImageFirebase(fileMobile);
        dispatch(updatePopupValue("imageUrl", uploadedImage));
        dispatch(updatePopupValue("imageUrlMobile", uploadedImageMobile));
        setLoadingFirebase(false);
      }

      dispatch(syncPopupValues());
      setFile(null);
      setFileMobile(null);
    } catch (err) {
      setLoadingFirebase(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (popupError) {
      const timer = setTimeout(() => {
        dispatch(resetError());
      }, 4500);

      return () => clearTimeout(timer);
    }
  }, [popupError]);

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        dispatch(resetSuccess());
      }, 4500);

      return () => clearTimeout(timer);
    }
  }, [successMessage]);

  const handleTypeSelect = (option) => {
    if (popup?.id) {
      dispatch(updatePopupValue("availableTo", planIds));
    } else {
      let plans = [];

      if (!option?.selected) {
        plans = planIds.filter((id) => id !== option.key);
      } else {
        plans.push(...planIds, option.key);
      }

      setPlanIds(plans);
    }
  };

  const SecondaryButtonProps = React.useMemo(
    () => ({
      children: "Não",
      onClick: toggleTeachingBubbleVisible,
    }),
    [toggleTeachingBubbleVisible]
  );

  return (
    <Panel
      isOpen={openPanel}
      onDismiss={() => {
        dispatch(setPainelIsOpen(false));
        setFile(null);
        setFileMobile(null);
      }}
      headerText="Criar pop-up"
      closeButtonAriaLabel="Close"
      type={PanelType.smallFixedFar}
      isFooterAtBottom={true}
      styles={panelStyles}
    >
      <Container>
        {fieldError.title && (
          <span style={{ color: "red" }}>{fieldError.title}</span>
        )}
        {fieldError.availableTo && (
          <span style={{ color: "red" }}>{fieldError.availableTo}</span>
        )}
        {fieldError.imageUrl && (
          <span style={{ color: "red" }}>{fieldError.imageUrl}</span>
        )}
        <TextField
          label="Nome"
          placeholder="Nome do pop-up"
          value={popup?.title}
          onChange={(e) => validateFields("title", e.target.value)}
        />
        <DatePicker
          label="Válido até"
          placeholder="Selecione uma data..."
          value={popup?.expiresAt ? new Date(popup?.expiresAt) : null}
          showMonthPickerAsOverlay={true}
          onSelectDate={(date) => validateFields("expiresAt", date)}
          formatDate={(date) => moment(date).format("DD/MM/YYYY")}
        />
        <ComboBox
          label="Clientes"
          placeholder={popup?.id ? `${clientPlanStatus(popup.availableTo)}` : ""}
          options={userType}
          onChange={(e, option) => handleTypeSelect(option)}
          selectedKey={popup?.planId?.toUpperCase()}
          multiSelect
        />
        <label style={{ fontWeight: 500 }}>Imagem Web</label>
        <UploaderContainer>
          <FileUploader
            type="file"
            id="file"
            accept="image/*"
            onChange={(event) => {
              setFile(event?.target?.files[0]);
            }}
          />
          <label htmlFor="file">Carregar do meu computador</label>
          {file && (
            <>
              <div style={{ position: "relative" }}>
                <PreviewContainer>
                  <PreviewIcon aria-label="Image" iconName="FileImage" />
                  <div>
                    <span>{file?.name}</span>
                  </div>
                </PreviewContainer>
                <DeleteIcon>
                  <IconButton
                    title="Fechar"
                    iconProps={closeIcon}
                    ariaLabel="Fechar"
                    onClick={() => setFile(null)}
                  />
                </DeleteIcon>
              </div>
            </>
          )}
          {popup?.imageUrl !== "" && (
            <TextContainer>
              <a href={popup.imageUrl} target="_blank">
                {popup.imageUrl}
              </a>
            </TextContainer>
          )}
        </UploaderContainer>
        <label style={{ fontWeight: 500 }}>Imagem Mobile</label>
        <UploaderContainer>
          <FileUploader
            type="file"
            id="fileMobile"
            accept="image/*"
            onChange={(event) => {
              setFileMobile(event?.target?.files[0]);
            }}
          />
          <label htmlFor="fileMobile">Carregar do meu computador</label>
          {fileMobile && (
            <>
              <div style={{ position: "relative" }}>
                <PreviewContainer>
                  <PreviewIcon aria-label="Image" iconName="FileImage" />
                  <div>
                    <span>{file?.name}</span>
                  </div>
                </PreviewContainer>
                <DeleteIcon>
                  <IconButton
                    title="Fechar"
                    iconProps={closeIcon}
                    ariaLabel="Fechar"
                    onClick={() => setFileMobile(null)}
                  />
                </DeleteIcon>
              </div>
            </>
          )}
          {popup?.imageUrlMobile !== "" && (
            <TextContainer>
              <a href={popup.imageUrlMobile} target="_blank">
                {popup.imageUrlMobile}
              </a>
            </TextContainer>
          )}
        </UploaderContainer>
        <Toggle
          label="Ativo"
          onText="Sim"
          offText="Não"
          checked={popup?.isActive}
          onChange={(e, checked) =>
            dispatch(updatePopupValue("isActive", checked))
          }
        />
        <ActionsContainer>
          {popup?.id && (
            <>
              <Stack.Item align="start">
                <DefaultButton
                  id={buttonId}
                  text="Excluir"
                  disabled={loadingFirebase || popupLoading}
                  onClick={toggleTeachingBubbleVisible}
                />
              </Stack.Item>
              {teachingBubbleVisible && (
                <TeachingBubble
                  target={`#${buttonId}`}
                  primaryButtonProps={{
                    children: "Sim",
                    onClick: () => {
                      toggleTeachingBubbleVisible();
                      dispatch(deletePopup(popup.id));
                    },
                  }}
                  secondaryButtonProps={SecondaryButtonProps}
                  onDismiss={toggleTeachingBubbleVisible}
                  headline="Tem certeza que deseja excluir esse popup?"
                />
              )}
            </>
          )}
          <Stack.Item align="end">
            <PrimaryButton
              text={
                loadingFirebase || popupLoading ? "Carregando..." : "Salvar"
              }
              onClick={dispatchForm}
              disabled={
                loadingFirebase ||
                popupLoading ||
                fieldError.name ||
                fieldError.percentage
              }
            />
          </Stack.Item>
        </ActionsContainer>
        {popupError && (
          <MessageBar messageBarType={MessageBarType.error}>
            {popupError}
          </MessageBar>
        )}
        {successMessage && (
          <MessageBar messageBarType={MessageBarType.success}>
            {successMessage}
          </MessageBar>
        )}
      </Container>
    </Panel>
  );
};

export default PopupPanel;
