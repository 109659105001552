import { DefaultButton, Stack } from "@fluentui/react";
import moment from "moment";
import { TextContainer } from "./style";

// Plan Names
const planNames = (availableTo) => {
  switch (availableTo) {
    case 0:
      return "Ativos";
    case 2:
      return "Cancelados";
    case 3:
      return "Expirados";
    case 4:
      return "Free";
    case 5:
      return "Todos";
    default:
      return availableTo;
  }
};

function mapAndConvert(array, matchValueMap) {
  return array
    .map((x) => matchValueMap[x] || null)
    .filter((x) => x !== null)
    .join(", ");
}

export const clientPlanStatus = (items) => {
  const array = items;
  const matchValueMap = {
    0: "Ativo",
    2: "Cancelados",
    3: "Expirados",
    4: "Free",
    5: "Todos",
  };

  const result = mapAndConvert(array, matchValueMap);

  return result;
};

// Plan Names
const planStatus = (active) => {
  if (active) return "Ativo";
  if (!active) return "Desativado";
  return "";
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (onEdit) => [
  {
    key: "column0",
    name: "Nome",
    fieldName: "title",
    minWidth: 190,
    maxWidth: 190,
    isResizable: true,
    styles: "styleColumn",
  },
  {
    key: "column1",
    name: "Status",
    fieldName: "isActive",
    minWidth: 150,
    maxWidth: 240,
    isResizable: false,
    styles: "styleColumn",
    onRender: (item) => planStatus(item?.isActive),
  },
  {
    key: "column2",
    name: "Imagem Web",
    fieldName: "imageUrl",
    minWidth: 160,
    maxWidth: 240,
    styles: "styleColumn",
    isResizable: true,
    onRender: (item) => (
      <TextContainer>
        <a href={item.imageUrl} target="_blank">
          {item.imageUrl}
        </a>
      </TextContainer>
    ),
  },
  {
    key: "column2",
    name: "Imagem Mobile",
    fieldName: "imageUrlMobile",
    minWidth: 160,
    maxWidth: 240,
    styles: "styleColumn",
    isResizable: true,
    onRender: (item) => (
      <TextContainer>
        <a href={item.imageUrlMobile} target="_blank">
          {item.imageUrlMobile}
        </a>
      </TextContainer>
    ),
  },
  {
    key: "column3",
    name: "Clientes",
    fieldName: "availableTo",
    minWidth: 200,
    maxWidth: 250,
    styles: "styleColumn",
    isResizable: true,
    onRender: (item) => clientPlanStatus(item?.availableTo),
  },
  {
    key: "column5",
    name: "Válido até",
    fieldName: "expiresAt",
    minWidth: 140,
    isResizable: true,
    styles: "styleColumn",
    onRender: (item) =>
      moment(item.expiresAt).locale("pt-br").format("DD/MM/YYYY"),
  },
  {
    key: "column6",
    name: "Ações",
    fieldName: "",
    minWidth: 110,
    maxWidth: 110,
    isResizable: true,
    styles: "styleColumn",
    onRender: (item) => (
      <Stack horizontal>
        <DefaultButton text="Editar" onClick={() => onEdit(item.id)} />
      </Stack>
    ),
  },
];
